import axios from 'axios';
export default axi
function axi(url, params) {
    return new Promise(function (resolve, reject) {       
            axios({
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'charset': 'utf-8' },
                url: 'https://businessmod.ru/cupSharing/api/' + url,
                data: {
                    "jsonrpc": "2.0",
                    "id": 1,
                    "params": params
                },
                responseType: 'json',
            })
                .then((res) => {
                    console.log(res)
                    resolve(res.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });  
};  
    