import React from 'react'
import Svg, { Path, ClipPath, Rect } from 'react-native-svg'
export const CupHP = props => (   
    <Svg baseProfile="tiny" xmlSpace="preserve"
    width="100%" height="100%" 
    viewBox="0 0 400 400" 
    preserveAspectRatio="none" x="0px" y="0px" 
    {...props}
    >
        <ClipPath id="clip">
            <Path
                d="M330.5 30.5h-261C63.15 30.5 58 35.65 58 42s5.15 11.5 11.5 11.5h2.94l22.39 308.43h20.51v7.57h169.32v-7.57h20.51L327.56 53.5h2.94c6.35 0 11.5-5.15 11.5-11.5s-5.15-11.5-11.5-11.5zM148.46 230.24l.2-2.82c.06-.88.83-1.55 1.71-1.48a1.6 1.6 0 011.48 1.71l-.63 8.77-8.77-.63a1.6 1.6 0 01-1.48-1.71c.06-.88.83-1.55 1.71-1.48l3.76.27c-17.25-21.73-19.7-52.01-5.9-76.31 18.62-32.78 60.45-44.3 93.23-25.68l-1.58 2.78c-31.25-17.75-71.12-6.77-88.87 24.48-13.01 22.92-10.85 51.43 5.14 72.1zm39.8-30.19c1.52 2.82 3.66 5.01 6.42 6.56 2.76 1.55 5.91 2.33 9.44 2.33 6.02 0 11.13-2.54 15.35-7.63l11.72 10.61c-3.16 3.97-7.13 7.01-11.91 9.12-4.78 2.11-10.14 3.16-16.1 3.16-6.82 0-12.95-1.44-18.38-4.33-5.43-2.88-9.69-6.9-12.79-12.05-3.1-5.15-4.65-10.98-4.65-17.49 0-6.51 1.55-12.34 4.65-17.49 3.1-5.15 7.36-9.16 12.79-12.05 5.43-2.88 11.55-4.33 18.38-4.33 5.95 0 11.32 1.06 16.1 3.16 4.78 2.11 8.75 5.15 11.91 9.12l-11.72 10.61c-4.22-5.09-9.34-7.63-15.35-7.63-3.54 0-6.68.78-9.44 2.33s-4.9 3.74-6.42 6.56c-1.52 2.82-2.28 6.06-2.28 9.72 0 3.66.76 6.9 2.28 9.72zm71.2 24.05c-12.58 22.15-35.75 34.59-59.57 34.59-11.44 0-23.02-2.87-33.66-8.91l1.58-2.78a64.77 64.77 0 0032.09 8.49c22.7 0 44.79-11.86 56.78-32.97 12.19-21.46 11.14-48.06-2.51-68.47l-1.01 3.05a1.606 1.606 0 01-2.02 1.02 1.606 1.606 0 01-1.02-2.02l2.77-8.34 8.34 2.77c.84.28 1.29 1.18 1.02 2.02-.28.84-1.18 1.29-2.02 1.02l-3.27-1.08c14.19 21.36 15.25 49.17 2.5 71.61z"
            />
        </ClipPath>
        <Rect
            x="0"
            y="0"
            width="100%"
            height={400-props.hp}
            fill="#EBBFA2"
            opacity="0.7"
            clipPath="url(#clip)"
        />
        <Rect
            x="0"
            y={400-props.hp}
            width="100%"
            height={props.hp}
            fill="#9D574F"
            opacity="0.9"
            clipPath="url(#clip)"
        />
    </Svg>
)