import React from 'react';
import { Text, View, Platform, TextInput, TouchableOpacity, AsyncStorage  } from 'react-native';
import axi from '../../functions/axiosf'
import { Context } from '../../components/contexts'
import { themes } from '../../constants/Colors';
import { styles } from '../../constants/Styles'; 
import PhoneMaskInput from '../../components/PhoneMaskInput/PhoneMaskInput';
import { Ionicons } from '@expo/vector-icons';

export default class PhoneLogin extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      num: '',
      numExist: false,
      numSave: false,
      pinView: false,
      pin: '',
      pinFull: false,
      pinWrong: false,
      pinOk: false,
    }
    }

  
  _setNum =(val)=>{
    this.setState({ 
      num: val,
      numExist: false,
      numSave: false,
      pinView: false,
      pin: '',
      pinFull: false,
      pinWrong: false,
      pinOk: false, })
    if(val.length>15){
      this.setState({ pinView: true })
      axi("phoneCheck.php", { phone: val, uid: this.context.userId }).then((result) => {
        if (result.type == 'approved'){
          this.setState({ numExist: true })
        } else {
          this.setState({ numExist: false })
        }
        this.setState({ pinView: result.queue })
      }, (e) => { console.log(e) })
    }else{
      this.setState({ pinView: false })
    }
  }

  _setPin = (text) => {
    if (text.length <= 4) {
      this.setState({ pin: text })
    }
    if (text.length == 4) {
      this.setState({ pinFull: true })
      axi("pinCheck.php", { pin: text, uid: this.context.userId }).then((result) => {
        if (result.type == 'approved') {
          this.setState({ pinWrong: false, pinOk: true })
        } else {
          this.setState({ pinWrong: true })
        }
      }, (e) => { console.log(e) })
    } else {
      this.setState({ pinFull: false })
    }
  }

  _logIn = () => {
    if(this.state.numExist){
      //тут пишем запрос на добавление ресурсов из текущего аккаунта в аккаунт введённого телефона, 
      //удаления текущего аккаунта и 
      
      axi("summationWithPhoneAccount.php", { tel: this.state.num, qr: this.context.userId }).then((result) => {
        if (result.type == 'approved') {
          //записи аккаунта введённого телефона в контекст и в память телефона
          AsyncStorage.setItem("userId", result.customer.qr)
          this.context.toggleUserID(result.customer.qr)
          this.context.smsRegistrationAdd(result.customer.phone)
          AsyncStorage.setItem("phone", result.customer.phone)
          if (Platform.OS === "web") { window.history.back() }
          else { this.props.navigation.navigate(this.props.navigation.state.params.fromScreen) }
        } else {
          //this.setState({  })
        }
      }, (e) => { console.log(e) })


    }else{
      //тут пишем номер телефона в память устройства и в контекст, 
      //а на сервер отправляем текущий аккаунт и номером телефона,
      //чтобы сохранить этот номер в текущем аккаунте.
      axi("addPhoneToAccount.php", { tel: this.state.num, qr: this.context.userId }).then((result) => {
        if (result.type == 'approved') {
          //записи аккаунта введённого телефона в контекст и в память телефона
          this.context.smsRegistrationAdd(this.state.num)
          AsyncStorage.setItem("phone", "" + this.state.num)
          if (Platform.OS === "web") { window.history.back() }
          else { this.props.navigation.navigate(this.props.navigation.state.params.fromScreen) }
        } else {
          console.log(result)
          //this.setState({  })
        }
      }, (e) => { console.log(e) })
    }  
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: themes[this.context.theme].allBackground,
        }}>
        <TouchableOpacity
          onPress={() => {
            if (Platform.OS === "web") {window.history.back()}
            else { this.props.navigation.navigate(this.props.navigation.state.params.fromScreen)}
            }}
          style={{
            position: 'absolute',
            right: -15,
            top: 45,
            backgroundColor: '#444',
            color: '#fff',
            height: 50,
            width: 50,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 50,
            zIndex: 100,
          }}>
          <Ionicons
            name="ios-arrow-back"
            size={30}
            color="#fff"
          />
        </TouchableOpacity>
        <Text style={{
          textAlign: "center",
          paddingHorizontal: 60,
          paddingVertical: 30,
          fontSize: 18,
          color: themes[this.context.theme].text,
          height: 'auto',
        }}>
          {'Введите номер телефона'}
        </Text>
          <PhoneMaskInput
            flagSize={'big'}
            valueN={this.state.num}
            placeholder={'Введите номер телефона'}
            onChange={(val) => this._setNum(val)}
            autoDetectCountry={true}
          />
          {this.state.pinView &&
            <Text style={{
              color: themes[this.context.theme].text, 
              textAlignVertical: "center", 
              padding: 10,
              paddingHorizontal: 35}}>
                {this.state.numExist &&'Этот номер уже зарегистрирован в системе, '}{'введите код подтверждения #'+this.state.pinView+' из SMS:'}
            </Text>
          }
          {this.state.pinView &&  
            <TextInput 
              style={{ ...styles.textInput, 
                height: 100, 
                fontSize: 60, 
                width: 200,
                color: this.state.pinWrong ? '#b11002' :themes[this.context.theme].text, 
                borderBottomColor: this.state.pinWrong ? '#b11002' : themes[this.context.theme].text,
              }}
              placeholder={'____'}
              onChangeText={text => this._setPin(text)}
              value={this.state.pin}
              maxLength={4}
              keyboardType='numeric'
            />
          }

          {this.state.pinOk && !this.state.pinWrong &&
            <TouchableOpacity
              onPress={() => {
                this._logIn()
              }}>
              <Text
                style={{
                  ...styles.textInput,
                  backgroundColor: themes[this.context.theme].tabIconSelected,
                  color: '#fffd',
                  elevation: 4,
                  height: 'auto'
                }}>
                  {this.state.numExist ? 'Войти и объеденить ресурсы' : "Зарегистрироваться"}
              </Text>
            </TouchableOpacity>  
          }
      </View>
    );
  }

}

PhoneLogin.contextType = Context;

PhoneLogin.navigationOptions = {
  header: null,
}; 