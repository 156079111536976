import { createBrowserApp } from '@react-navigation/web';
import { createSwitchNavigator, createStackNavigator } from 'react-navigation';
import {Platform} from 'react-native'

import MainTabNavigator from './MainTabNavigator';
import PhoneLogin from '../screens/LogIn/PhoneLogin'

const config = Platform.select({
  web: { headerMode: 'screen' },
  default: {},
});

const Login = createStackNavigator({
  PhoneLogin: PhoneLogin,
}, config)

const switchNavigator = createSwitchNavigator({
  // You could add another route here for authentication.
  // Read more at https://reactnavigation.org/docs/en/auth-flow.html
  Main: MainTabNavigator,
  Login: Login,
});
switchNavigator.path = '';

export default createBrowserApp(switchNavigator, { history: 'hash' });
