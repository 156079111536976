import React from 'react';

export const Context = React.createContext({
    theme: 'light',
    toggleTheme: () => { },
    modalTheme: 'off',
    toggleModalTheme: () => { },
    lang: 'ru',
    toggleLang: () => { },  
    userId: null,
    toggleUserID: () => { },
    hp: 0,
    toggleHP: () => { },
    avaURL: null,
    toggleAva: () => { },
    profile: {
        name: null,
        sex: null,
        age: null,
    },
    toggleProfile: () => { },
});
/*
export const ModalContext = React.createContext({
    modal: null,
    toggleModal: () => {},});
    */