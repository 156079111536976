import React from 'react';
import { View, Platform } from 'react-native';

import { Context } from '../../components/contexts'
import { themes } from '../../constants/Colors';
import MapView from 'react-native-maps';
import * as Location from 'expo-location';
import * as Permissions from 'expo-permissions';
import axi from '../../functions/axiosf'

export default class LinksScreen  extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      location_y: 30.312657,
      location_x: 59.928320,
      markers: [],
      isMapReady: false,
    }

  }
  componentDidMount() {
    axi("CafeBase.php", { location: "null" }).then((result) => {
      this.setState({ markers: result })
    }, (e) => { console.log(e) })

    this._getLocationAsync();
  }

  _getLocationAsync = async () => {
    let { status } = await Permissions.askAsync(Permissions.LOCATION);
    let location = await Location.getCurrentPositionAsync({});
    if (location && status){
      //console.info(location)
      this.setState({
        'location_x': (location.coords.latitude) ? location.coords.latitude : 59.928320, 
        'location_y': (location.coords.longitude) ? location.coords.longitude : 30.312657, 
        'isMapReady': true })
    }
    if (Platform.OS !== 'web') {
      this.mapViewRef.animateToRegion({
        latitude: (location.coords.latitude) ? location.coords.latitude : 59.928320,
        longitude: (location.coords.longitude) ? location.coords.longitude : 30.312657,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      })
    }

  };

  render() {
    if (Platform.OS === 'web'){
      return (
        <View style={{
          backgroundColor: themes[this.context.theme].allBackground, 
          height: "100%",
          width: "100%"}}>
          <MapView
            ref={(mapViewRef) => { this.mapViewRef = mapViewRef }}
            style={{
             height: "100%",
              width: "100%"
            }}
            region={{
              latitude: +this.state.location_x,
              longitude: +this.state.location_y,
              latitudeDelta: 0.0922,
              longitudeDelta: 0.0421,
            }}

          >
            {this.state.isMapReady &&
              <MapView.Marker
                title={"мы тут"}
                pinColor = {"#745A55"}
                coordinate={{
                  latitude: +this.state.location_x,
                  longitude: +this.state.location_y,
                }}
                
              />
              }
            {this.state.markers.map(marker => (
              <MapView.Marker
                key={marker.id}
                coordinate={{
                  latitude: +marker.location_x,
                  longitude: +marker.location_y,
                }}
                title={marker.name}
                description={"Кружек: " + marker.number_cups}
                pinColor={(+marker.number_cups > 0) ? "#ACBA7D" : "#f99"}

              />
            ))}
          </MapView>
        </View>
      );
    }else{
      return(
      <View style={{ backgroundColor: themes[this.context.theme].allBackground, }}>
        <MapView
          ref={(mapViewRef) => { this.mapViewRef = mapViewRef }}
          style={{
            height: "100%",
            width: "100%"
          }}
          initialRegion={{
            latitude: +this.state.location_x,
            longitude: +this.state.location_y,
            latitudeDelta: 0.0922,
            longitudeDelta: 0.0421,
          }}
          provider='google'
          mapType='standard'
          showsScale
          showsCompass
          showsPointsOfInterest
          showsBuildings
          onLayout={this.onMapLayout}
        >
          {this.state.isMapReady &&
              <MapView.Marker
              title={"мы тут"}
              pinColor={"#745A55"}
              coordinate={{
                latitude: +this.state.location_x,
                longitude: +this.state.location_y,
              }}

            />
          }
          {this.state.markers.map(marker => (
            <MapView.Marker
              key={marker.id}
              coordinate={{
                latitude: +marker.location_x,
                longitude: +marker.location_y,
              }}
              title={marker.name}
              description={"Кружек: " + marker.number_cups}
              pinColor={(+marker.number_cups > 0) ? "#ACBA7D" : "#f99"}

            />
          ))}
        </MapView>
      </View>
      )
  }  
      }
}

LinksScreen.contextType = Context;

LinksScreen.navigationOptions = {
  header: null,
};

