import React from 'react';
import { Text, ScrollView, View , TouchableOpacity, Platform } from 'react-native';
import { Context } from '../../components/contexts'
import { CupHP } from '../../components/svg/CupHP'
import { themes } from '../../constants/Colors'; 
import Swiper from 'react-native-swiper';
import { lay } from '../../constants/Layout'
import { styles } from '../../constants/Styles'; 
import SwipeItem from './SwipeItem'
import axi from '../../functions/axiosf'
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';

export default class SettingsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      volume_cups: 0,
      busy_cups: 0,
      cups: [
        {
          idSource: 0,
          hp: 0,
          locking: false,
          time: 0,
        }
      ],
      telBan: 0,
    }
  }

  componentDidMount() {
    this._axiReadClientSources()
  }

  _axiReadClientSources = () => {
      axi("readClientSources.php", { qr: this.context.userId }).then((result) => {
      if (result.sources){
        let busyCups = 0
        for (let i = 0; i < result.sources.length; i++){
          if (result.sources[i].locking == 1) { busyCups++ }
        }
        this.setState({ cups: result.sources, volume_cups: result.length, busy_cups: busyCups, telBan: +result.phoneBan})
      }
    }, (e) => { console.log(e) })
  }

  _toggleTelBan=()=>{
    let ban = this.state.telBan ? 0 : 1
    axi("toggleTelBan.php", { qr: this.context.userId,  ban: ban}).then((result) => {
      if (result.sources) {
        let busyCups = 0
        for (let i = 0; i < result.sources.length; i++) {
          if (result.sources[i].locking == 1) { busyCups++ }
        }
        this.setState({ cups: result.sources, volume_cups: result.length, busy_cups: busyCups})
      }
      this.setState({ telBan: +result.phoneBan })
    }, (e) => { console.log(e) })
  }

  render (){
    return(
      <ScrollView style={{ 
        paddingTop: 0,
        backgroundColor: themes[this.context.theme].allBackground, 
        flex: 1,
        flexDirection: "column",}}
       >
        <TouchableOpacity
          onPress={() => this._axiReadClientSources()}
          style ={{
            position: "absolute",
            right: 15,
            top: 45,
            width: 40,
            height: 40,
            borderRadius: 20,
            backgroundColor: "#0006",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            zIndex: 100,
          }}
        >
          <Ionicons
            name={"md-refresh"}
            size={26}
            //style={{ marginBottom: -3 }}
            color={"#fff"}
          />
        </TouchableOpacity>
        {(this.state.cups != [
          {
            idSource: 0,
            hp: 0,
            locking: false,
            time: 0,
          }
        ]) ?
          (Platform.OS === 'web') ?
          <View
          style={{
            flexDirection: 'row',
            overflow: 'scroll'
          }}>
              <View
                style={{
                  flexDirection: 'row',
                  width: 300 * this.state.cups.length
                }}>
                  {this.state.cups.map((item, index) => <SwipeItem item={item} key={index} />)}
              </View>
          </View>
          :
          <Swiper autoplayTimeout = {15}
            autoplay = {true}
              timeout={15}
              loop={true}
            activeDot = {<View style={{backgroundColor: '#745A55', width: 10, height: 10, borderRadius: 4, marginLeft: 3, marginRight: 3, marginTop: 3, marginBottom: 3,}} />}
              height={390} width={lay.width} showsButtons={false}>
            {this.state.cups.map((item, index) => <SwipeItem item={item} key={index}/>)}
          </Swiper>
        :
          <Text style={{ ...styles.text, flex: 1, }}>{"Добро пожаловать! Приобретите обонемент на 10 напитков в любом заведении, указанном на карте. Стоимость всего 100 рублей"}</Text>
        }
          <View style={{
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch"
          }}>
            {!this.context.smsRegistration &&
              <TouchableOpacity
                onPress={() => {
              this.props.navigation.navigate('PhoneLogin', { fromScreen: 'Settings'})
                }}>
                <Text style={{
                  ...styles.textInput,
                  backgroundColor: themes[this.context.theme].tabIconSelected,
                  color: '#fffd',
                  elevation: 4,
                }}>
                  {"Зарегистрироваться"}
                </Text>
              </TouchableOpacity>
            }
            <View style={styles.tabRowText}>
              <Text style={{ ...styles.text, flex: 6, textAlign: "left", }}>кружек на балансе: </Text>
              <Text style={{ ...styles.text, flex: 1, textAlign: "right", }}>{this.state.volume_cups}</Text>
            </View>
            <View style={styles.tabRowText}>
              <Text style={{ ...styles.text, flex: 6, textAlign: "left", }}>из них у Вас на руках: </Text>
              <Text style={{ ...styles.text, flex: 1, textAlign: "right", }}>{this.state.busy_cups}</Text>
            </View>
            {this.state.busy_cups>0 &&
              <Text style={{ ...styles.text}}>сдайте в ближайшую кофейню все кружки, которые у Вас на руках</Text>
            }
            {this.context.smsRegistration &&
            <TouchableOpacity 
            onPress = {()=>this._toggleTelBan()}
            style={{...styles.tabRowText, backgroundColor: themes[this.context.theme].tabIconSelected}}>
            <Text style={{ ...styles.text, flex: 4, textAlign: "left", color: '#fffd' }}>{this.state.telBan ? 'не ' : ''}{'выдавать кружки по номеру телефона '}</Text>
              <MaterialCommunityIcons 
                name={this.state.telBan ? "close-box-outline" :"checkbox-marked-outline"} 
                style={{ color: '#fffd', flex: 1, marginRight: 20, textAlign: "right", }} 
                size={40}  />
            </TouchableOpacity>
            }
          </View>
                    
      </ScrollView>
    
    )
  }
}

SettingsScreen.contextType = Context;

SettingsScreen.navigationOptions = {
  header: null,
};
