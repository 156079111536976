import React from 'react';
import { ScrollView, View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { themes } from '../../constants/Colors'; 
import { Context } from '../../components/contexts'
import { styles } from '../../constants/Styles';
import axi from '../../functions/axiosf'
import moment from 'moment';
import 'moment/locale/ru';
import { Ionicons } from '@expo/vector-icons';

export default class Log extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      actions: [
      ],
      markers:[],
    }

  }
  componentDidMount() {
    this._axiReadBarLog()
  }

  _axiReadBarLog =()=>{
     axi("readCustomerLog.php", { "qr": this.context.userId }).then((result) => {
      this.setState({ actions: result })
      //console.log("result", result)
    }, (e) => { console.log(e) })
    axi("CafeBase.php", { location: "null" }).then((result) => {
      this.setState({ markers: result })
    }, (e) => { console.log(e) })
  }

  render() {
    return (
      <View style={{height: '100%', width: '100%'}}>
        <TouchableOpacity
          onPress={() => this._axiReadBarLog()}
          style={{
            position: "absolute",
            right: 30,
            top: 75,
            width: 40,
            height: 40,
            borderRadius: 20,
            backgroundColor: "#0006",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            zIndex: 100,
          }}
        >
          <Ionicons
            name={"md-refresh"}
            size={26}
            //style={{ marginBottom: -3 }}
            color={"#fff"}
          />
        </TouchableOpacity>
        <ScrollView style={{
          paddingTop: 0,
          flex: 1,
          flexDirection: "column",
        }}  
        >
          {this.state.actions.map((item, index)=> {
            let d = moment.unix(item.time);
            let dayMon = moment(d).format('lll');
            let backgroundC = themes[this.context.theme].Background1
            let textOperation = "Сдана"
            let cafe={}
            if (item.operation === "toSell") { 
              backgroundC = themes[this.context.theme].tabIconSelected
              textOperation = "Куплена"
              }
            if (item.operation === "giveOut") { 
              backgroundC = themes[this.context.theme].Background2 
              textOperation = "Получена"
              }
            if (item.operation === "LogIn") {
              backgroundC = themes[this.context.theme].title
              textOperation = "Вход"
            }
            this.state.markers.map((itemJ, indexJ) => { 
              if (itemJ.id == +item.cafe_id){
                cafe=itemJ
              }
            })

            return(<View key={index}
            style={{
              marginTop: 15,
              flexDirection: "column",
              backgroundColor: backgroundC,
              justifyContent: "space-around",
              alignItems: "center",
              marginHorizontal: 15,
              padding: 5,
              borderRadius: 15,
              overflow: 'hidden',
              elevation: 3,
            }}>
          <Text style={{ ...styles.text, 
            flex: 1, 
            textAlign: "left", paddingRight: 5, 
            color: (item.operation === "Take") ? "#2229" : "#fffd" }}>{textOperation+' в '+cafe.name}</Text>
          <Text style={{
                ...styles.text, marginVertical: 0,
                color: (item.operation === "Take") ? "#2229" : "#fffd"
              }}>{cafe.address}</Text>
          <Text style={{ ...styles.text, 
            flex: 2, textAlign: "right", 
            color: (item.operation === "Take") ? "#2229" : "#fffd"}}>{dayMon}</Text>

        </View>)
      })}
      </ScrollView>
    </View>
    );
  }
}

Log.contextType = Context;
Log.navigationOptions = {
  //title: 'Лента событий',
  header: null,
};

