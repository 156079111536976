const tintColor = '#585B48';
const greenCup = '#dceea2'

export const styles = {
  text: {
    flex: 1,
    textAlignVertical: "center",
    textAlign: "center",
    marginHorizontal: 30,
    marginVertical: 10,
    fontSize: 18,
    color: '#1119',
  },
  textWarning: {
    height: 40,
    //textAlignVertical: "center",
    textAlign: "center",
    marginHorizontal: 30,
    marginVertical: 10,
    paddingHorizontal: 16,
    paddingVertical: 8,
    fontSize: 18,
    backgroundColor: "#f009",
    borderRadius: 10,
    color: '#fffd',
  },
  textInput: {
    height: 40,
    textAlign: "center",
    marginHorizontal: 30,
    marginVertical: 10,
    paddingHorizontal: 16,
    paddingVertical: 7,
    fontSize: 18,
    backgroundColor: "#fffd",
    borderRadius: 16,
    color: tintColor,
    borderColor: tintColor,
    borderWidth: 2,
  },
  tabRowText: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
};