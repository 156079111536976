const tintColor = '#585B48'//'#005462';
const greenCup = '#dceea2'

export const themes = {
  light: {
    //tintColor,
    tabIconDefault: '#F2ECE3',
    tabIconSelected: tintColor,
    title: '#757F8C',
    text: '#333',
    tabBar: '#A5B898',
    tabBarOp: '#A5B898dd',
    allBackground: '#F2ECE3',
    allBackgroundOp: '#F2ECE399',
    Background1: '#EBBFA2',
    Background2: '#9D574F',
    inputBackground: '#fff',
    inputText: '#4F4F4F',
    avaIcon: '#F2ECE3',
    buttonNextColor: '#56CCF2',
    buttonNextDialogColorOn: '#00CAEB',
    buttonNextDialogColorOff: '#F2994A',
    buttonNextTextColor: '#fff',
    placeholderTextColor: '#c8ccd1',
    //#3B414B
    barTarget: '#fff',
    iconActivityPlus: '#FFF',

    buttonTextColor: '#757F8C',
    tinyTextColor: '#BDBDBD',
    noticeText: '#fffa',

    green: '#6FCF97',
    errorBackground: '#9D574F',
    errorText: '#fff',
    warningText: '#9D574F',

    warningBackground: '#EAEB5E',
    noticeBackground: tintColor,


  },
  dark: {
    //tintColor,
    tabIconDefault: '#E0E0E0',
    tabIconSelected: '#00CAEB',
    title: '#9aa3ae',
    text: '#eee',
    tabBar: '#4F4F4F',
    tabBarOp: '#4F4F4Fdd',
    allBackground: '#414141',
    allBackgroundOp: '#41414155',
    inputBackground: '#757F8C',
    inputText: '#DADADA',
    avaIcon: '#DADADA',
    buttonNextColor: '#56CCF2',
    buttonNextDialogColorOn: '#00CAEB',
    buttonNextDialogColorOff: '#F2994A',
    buttonNextTextColor: '#fff',
    placeholderTextColor: '#c8ccd1',
    barTarget: '#333333',
    iconActivityPlus: '#FFF',
    noticeText: '#111a',
    green: '#6FCF97',

    buttonTextColor: '#fff',
    tinyTextColor: '#eee',
    errorBackground: 'red',
    errorText: '#fff',
    warningText: '#F7362F',

    warningBackground: '#EAEB5E',
    noticeBackground: tintColor,

  }
};

export const naviThemes = {
  off: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    shadowColor: "#000",
    elevation: 10,
  },
  bottomModal: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    shadowColor: "transparent",
    elevation: 0,
  },
}