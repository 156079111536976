import React from 'react';
import { Platform, Text } from 'react-native';
import { createStackNavigator, createBottomTabNavigator, BottomTabBar } from 'react-navigation';
import { themes } from '../constants/Colors';
import { Context } from '../components/contexts'

import TabBarIcon from '../components/TabBarIcon';
import HomeScreen from '../screens/qrCode/HomeScreen';
import LinksScreen from '../screens/Map/LinksScreen';
import Log from '../screens/history/Log';
import SettingsScreen from '../screens/Account/SettingsScreen';

const config = Platform.select({
  web: { headerMode: 'screen' },
  default: {},
});

const TabBarComponent = props => <BottomTabBar {...props} />;

const HomeStack = createStackNavigator(
  {
    Home: HomeScreen,
  },
  config
);

HomeStack.navigationOptions = {
  //tabBarLabel: 'идентификация',
  tabBarLabel: ({ focused }) => (
    <Context.Consumer>
      {value => (
        <Text style={{
          color: focused ? themes.light.tabIconSelected : themes.light.tabIconDefault,
          textAlign: "center" }}>
          {'ID'}
        </Text>
      )}
    </Context.Consumer>
  ),
  tabBarIcon: ({ focused }) => (
    <TabBarIcon
      focused={focused}
      name={'ios-barcode'}
    />
  ),
};

HomeStack.path = '';

const LinksStack = createStackNavigator(
  {
    Links: LinksScreen,
  },
  config
);

LinksStack.navigationOptions = {
  //tabBarLabel: 'кофейни',
  tabBarLabel: ({ focused }) => (
    <Context.Consumer>
      {value => (
        <Text style={{
          color: focused ? themes.light.tabIconSelected : themes.light.tabIconDefault,
          textAlign: "center" }}>
          {'кофейни'}
        </Text>
      )}
    </Context.Consumer>
  ),
  tabBarIcon: ({ focused }) => (
    <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-map' : 'md-map'} />
  ),
};

LinksStack.path = '';

const LogStack = createStackNavigator(
  {
    Log: Log,
  },
  config
);

LogStack.navigationOptions = {
  //tabBarLabel: 'история',
  tabBarLabel: ({ focused }) => (
    <Context.Consumer>
      {value => (
        <Text style={{
          color: focused ? themes.light.tabIconSelected : themes.light.tabIconDefault,
          textAlign: "center"
        }}>
          {'история'}
        </Text>
      )}
    </Context.Consumer>
  ),
  tabBarIcon: ({ focused }) => (
    <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-list' : 'ios-list'} />
  ),
};

LogStack.path = '';

const SettingsStack = createStackNavigator(
  {
    Settings: SettingsScreen,
  },
  config
);

SettingsStack.navigationOptions = {
  //tabBarLabel: 'кабинет',
  tabBarLabel: ({ focused }) => (
    <Context.Consumer>
      {value => (
        <Text style={{ color: focused ? themes.light.tabIconSelected : themes.light.tabIconDefault, 
                      textAlign: "center"}}>
          {'кабинет'}
        </Text>
      )}
    </Context.Consumer>
  ),
  tabBarIcon: ({ focused }) => (
    <TabBarIcon focused={focused} name={Platform.OS === 'ios' ? 'ios-cafe' : 'md-pint'} />
  ),
};

SettingsStack.path = '';

const tabNavigator = createBottomTabNavigator({
  HomeStack,
  LinksStack,
  LogStack,
  SettingsStack,
}, 
{
  tabBarComponent: props => (
    <Context.Consumer>
      {value => (
        <TabBarComponent {...props} style={{
          backgroundColor: themes.light.tabBar,
          borderTopColor: themes.light.tabBar,
          border: 0,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          shadowOffset: {
            width: 0,
            height: 5,
          },
          shadowOpacity: 0.34,
          shadowRadius: 6.27,

          elevation: 7,
        }} />
      )}
    </Context.Consumer>
  ),
}
);

tabNavigator.path = '';

export default tabNavigator;
