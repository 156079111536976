import { AppLoading } from 'expo';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import React, { useState } from 'react';
import { Platform, StatusBar, View, AsyncStorage  } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import { themes } from './constants/Colors';
import { Context } from './components/contexts'
import axi from './functions/axiosf'

import AppNavigator from './navigation/AppNavigator';

export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      theme: "light",
      modalType: null,
      userId: "",
      toggleTheme: this.toggleTheme,
      toggleUserID: this.toggleUserID,
      hp: 8,
      toggleHP: this.toggleHP,
      isLoadingComplete: false,
      avaURL: null,
      toggleAva: this.saveAva,
      profile: {
        name: null,
        sex: null,
        age: null,
      },
      toggleProfile: this.toggleProfile,
      achievements: [],
      toggleAchievements: this.toggleAchievements,
      lang: 'ru',
      toggleLang: this.toggleLang,
      modalTheme: 'off',
      toggleModalTheme: this.toggleModalTheme,
      smsRegistration: null,
      smsRegistrationAdd: this.smsRegistrationAdd,
    }
  }

  toggleProfile = (mas) => {
    this.setState({ profile: mas })
  }
  saveAva = (u) => {
    this.setState({ avaURL: u })
  }
  toggleUserID = (u) => {
    this.setState({ userId: u })
  }
  toggleHP = (t) => {
    this.setState({ hp: t })
  }
  toggleLang = (m) => {
    this.setState({ lang: m })
  }
  toggleTheme = () => {
    this.setState(state => ({ theme: (state.theme == "dark") ? "light" : "dark", }))
  }
  smsRegistrationAdd = (s) => {
    this.setState({ smsRegistration: s})
  }

  componentDidMount() {
    AsyncStorage.getItem("userId").then((idt) => {
      if (idt != "" && idt != null && idt != undefined) {
        this.setState({ userId: idt })
        AsyncStorage.getItem("phone").then((t) => {
          if (t != "" && t != null && t != undefined) {
            this.setState({ smsRegistration: t })
          }
        }, (e) => { console.log(e) })
        console.log('есть пользователь в памяти')
      } else {
        console.log('НЕТ пользователя в памяти')
        axi("newClient.php", { location: "null" }).then((result) => {
          this.setState({ userId: ""+result.userId })
          AsyncStorage.setItem("userId", ""+result.userId)
        }, (e) => { console.log(e) })
      }
    }
    )
  }

  render() {
    if (!this.state.isLoadingComplete && !this.props.skipLoadingScreen) {
      return (
        <AppLoading
          startAsync={this._loadResourcesAsync}
          onError={this._handleLoadingError}
          onFinish={this._handleFinishLoading}///()=>{this.setState({ isLoadingComplete: true })}

        />
      );
    } else {
      return (
        <Context.Provider value={this.state}>
          <View style={{
            flex: 1,
            backgroundColor: themes[this.state.theme].allBackground,
          }}>
            {Platform.OS === 'ios' ? <StatusBar barStyle="default" /> : 
              Platform.OS === 'web'? 
                <View/>
              :
                <View style={{
                    backgroundColor: "#A5B898",
                  height: 25,
                  }} />
            }
            <AppNavigator />
          </View>
        </Context.Provider>
      );
    }
  }
  _loadResourcesAsync = async () => {

    return Promise.all([
      //Asset.loadAsync([
      //require('./assets/images/robot-dev.png'),
      //require('./assets/images/robot-prod.png'),
      //]),
      Font.loadAsync({
        // This is the font that we are using for our tab bar
        ...Ionicons.font,
        // We include SpaceMono because we use it in HomeScreen.js. Feel free to
        // remove this if you are not using it in your app
        'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf'),
        
      }),
    ]);
  }
  _handleLoadingError = error => {
    // In this case, you might want to report the error to your error
    // reporting service, for example Sentry
    console.warn(error);
  };
  _handleFinishLoading = () => {
    console.log(this.state)
    this.setState({ isLoadingComplete: true });
  };
 
}

