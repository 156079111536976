import React from 'react';

import { Text, ScrollView, View  } from 'react-native';
import { Context } from '../../components/contexts'
import { CupHP } from '../../components/svg/CupHP'
import { themes } from '../../constants/Colors'; 
import { styles } from '../../constants/Styles'; 
import { lay } from '../../constants/Layout'

export default class SwipeItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      style:{ 

      },
    }
  }

  componentDidMount() {

    console.log(lay.window.width)
  }

  render()  {
    return (
      <View
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}>  
          <View style={{
              //height: lay.window.width-100,
              //width: lay.window.width-100,
              height: 300,
              width: 300,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}>
            <CupHP 
              style={{ 
                //height: lay.window.width, 
                //width: lay.window.width*0.78, 
                zIndex: 0 }} 
              fill={"#F2ECE3"} 
              //hp={(this.props.item.hp === 10) ? 400 : this.props.item.hp * 12 + 104}
              hp={this.props.item.hp * 40}
              />
          </View>
          <View style={{
            marginTop: -20,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center"
          }}>
            <View style={styles.tabRowText}>
              <Text style={{ ...styles.text, flex: 6, textAlign: "left", }}>ресурс этой кружки: </Text>
              <Text style={{ ...styles.text, flex: 1, textAlign: "right", }}>{this.props.item.hp * 10}%</Text>
            </View>
            {(this.props.item.locking == 1)?
            <Text style={{ ...styles.textWarning }}>{"сдайте эту кружку в кофейню"}</Text>
            :
            <Text style={{ ...styles.textWarning, backgroundColor: themes[this.context.theme].tabBar, }}>{"ждёт в ближайшей кофейне"}</Text>
            }
          </View>
      </View>
      )}
}

SwipeItem.contextType = Context;


